import typeToReducer from 'type-to-reducer'
import {
  FETCH_PAYMENT_DETAILS,
  OPEN_RSVP_ON_STEP,
  RESET_PAYMENT_DETAILS,
  SET_CHANGING_STATUS,
  SET_COMM_METHOD,
  SET_CURRENT_QUESTION_ID,
  SET_CURRENT_STEP,
  SET_EMAIL,
  SET_FORM_RESPONSES,
  SET_NEW_GUEST_MESSAGE,
  SET_NEW_GUEST_NAME,
  SET_NEW_GUEST_STATUS,
  SET_NOTIFICATION_TOKEN,
  SET_RESPONSE_REASON,
  SET_RSVP_LOCATION,
  SET_SCROLL_TO_CHAT,
  SET_SEARCH,
  SET_SELECTED_TICKET_PRODUCT,
  SET_JOIN_OPTION,
  START_CHECK_ORDER_STATUS,
  SET_NEW_GUEST_WAITINGLIST,
  RESET_FORM_RESPONSES,
} from './../actions/rsvp'

let localEmail = ''
let localName = ''
let localFirstName = ''
let localLastName = ''

if (typeof window !== 'undefined') {
  if (window.localStorage) {
    localEmail = window.localStorage.getItem('email')
    localName = window.localStorage.getItem('name')
    localFirstName = window.localStorage.getItem('firstName')
    localLastName = window.localStorage.getItem('lastName')
  }
}

const initialState = {
  newGuest: {
    status: 'NONE',
    name: localName || '',
    //    firstName: localFirstName || '',
    //    lastName: localLastName || '',
    emailAddress: localEmail || '',
    responseReason: '',
    formResponses: [],
    ticketOptions: [],
    message: '',
  },
  search: '',
  wasEmailFilled: !!localEmail,
  wasNameFilled: !!localName,
  successfulSubmit: false,
  changingStatus: false,
  currentStep: 'start',
  commMethod: null,
  selectedTicketProduct: -1,
  paymentDetails: null,
  paymentDetailsPending: false,
  paymentDetailsError: null,
  currentQuestionId: null,
  scrollToChat: false,
}

export default typeToReducer(
  {
    [SET_EMAIL]: (state, action) => ({
      ...state,
      newGuest: {
        ...state.newGuest,
        emailAddress: action.payload,
      },
    }),
    [SET_NOTIFICATION_TOKEN]: (state, action) => ({
      ...state,
      newGuest: {
        ...state.newGuest,
        notificationToken: action.payload,
      },
    }),
    [SET_RESPONSE_REASON]: (state, action) => ({
      ...state,
      newGuest: {
        ...state.newGuest,
        responseReason: action.payload,
      },
    }),
    [SET_CHANGING_STATUS]: (state, action) => ({
      ...state,
      changingStatus: action.payload,
    }),
    [SET_CURRENT_STEP]: (state, action) => ({
      ...state,
      currentStep: action.payload,
    }),
    [SET_SCROLL_TO_CHAT]: (state, action) => ({
      ...state,
      scrollToChat: action.payload,
    }),
    [RESET_PAYMENT_DETAILS]: (state) => ({
      ...state,
      paymentDetails: null,
    }),
    [OPEN_RSVP_ON_STEP]: (state, action) => ({
      ...state,
      currentStep: action.payload,
    }),
    [SET_SELECTED_TICKET_PRODUCT]: (state, action) => ({
      ...state,
      selectedTicketProduct: action.payload,
    }),
    [SET_CURRENT_QUESTION_ID]: (state, action) => ({
      ...state,
      currentQuestionId: action.payload,
    }),
    [SET_FORM_RESPONSES]: (state, action) => ({
      ...state,
      newGuest: {
        ...state.newGuest,
        questionsCompleted: true,
        formResponses: action.payload,
      },
    }),
    [RESET_FORM_RESPONSES]: (state) => ({
      ...state,
      newGuest: {
        ...state.newGuest,
        questionsCompleted: false,
        formResponses: [],
      },
    }),
    [SET_JOIN_OPTION]: (state, action) => ({
      ...state,
      newGuest: {
        ...state.newGuest,
        joinOption: action.payload,
      },
    }),
    [SET_COMM_METHOD]: (state, action) => ({
      ...state,
      commMethod: action.payload,
    }),
    [SET_SEARCH]: (state, action) => ({
      ...state,
      search: action.payload,
    }),
    [FETCH_PAYMENT_DETAILS]: {
      PENDING: (state, action) => ({
        ...state,
        paymentDetailsPending: true,
      }),
      FULFILLED: (state, action) => ({
        ...state,
        paymentDetailsPending: false,
        paymentDetails: action.payload,
      }),
      REJECTED: (state, action) => ({
        ...state,
        paymentDetailsPending: false,
        paymentDetailsError: action.payload,
      }),
    },
    [SET_NEW_GUEST_NAME]: (state, action) => ({
      ...state,
      newGuest: {
        ...state.newGuest,
        name: action.payload,
      },
    }),
    //    [SET_NEW_GUEST_FIRST_NAME]: (state, action) => ({
    //      ...state,
    //      newGuest: {
    //        ...state.newGuest,
    //        firstName: action.payload,
    //      },
    //    }),
    //    [SET_NEW_GUEST_LAST_NAME]: (state, action) => ({
    //      ...state,
    //      newGuest: {
    //        ...state.newGuest,
    //        lastName: action.payload,
    //      },
    //    }),
    [SET_NEW_GUEST_MESSAGE]: (state, action) => ({
      ...state,
      newGuest: {
        ...state.newGuest,
        message: action.payload,
      },
    }),
    [SET_NEW_GUEST_STATUS]: (state, action) => ({
      ...state,
      newGuest: {
        ...state.newGuest,
        status: action.payload,
      },
    }),
    [SET_NEW_GUEST_WAITINGLIST]: (state, action) => ({
      ...state,
      newGuest: {
        ...state.newGuest,
        waitingList: action.payload,
      },
    }),
    [SET_RSVP_LOCATION]: (state, action) => ({
      ...state,
      newGuest: {
        ...state.newGuest,
        location: action.payload,
      },
    }),
    [START_CHECK_ORDER_STATUS]: (state, action) => ({
      ...state,
      currentStep: 'check-order-status',
      orderId: action.payload,
    }),
  },
  initialState,
)
