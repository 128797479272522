import apiClient from 'shared-components/utils/ApiClient'

export const FETCH_FEATURED = 'FETCH_FEATURED'
export const FETCH_FEATURED_PAGES = 'FETCH_FEATURED_PAGES'

export const fetchFeatured = () => ({
  type: FETCH_FEATURED,
  payload: apiClient.featured.get()
})

export const fetchFeaturedPages = () => async (dispatch) => {
  const response = await fetch('https://api.bash.social/organisations/featured', {
    headers: {
      'Content-Type': 'application/json',
      // 'Accept-Language': locale
    }
  })
  const results = await response.json()
  dispatch({
    type: 'FETCH_FEATURED_PAGES_FULFILLED',
    payload: results,
  })
}
