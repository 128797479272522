import dayjs from 'dayjs'
import i18n from 'config/i18n'
import { initialEventDraft } from '@redux/eventHelpers'

export const sortGuests = (a, b) =>
  sortByIsAdded(a, b) ||
  sortByStatus(a, b) ||
  sortByIsUser(a, b) ||
  sortByName(a, b) ||
  0
export const sortGuestsWithCurrent = (currentGuestId) => (a, b) =>
  currentGuestId
    ? sortByCurrentGuest(currentGuestId, a, b) || sortGuests(a, b)
    : sortGuests(a, b)

export const personalCategories = {
  HOUSE_PARTY: {
    emoji: '🏠',
  },
  DANCE: {
    emoji: '💃',
  },
  CHAT: {
    emoji: '💬',
  },
  DRINKS: {
    emoji: '🍻',
  },
  GAME: {
    emoji: '🎲',
  },
  SPORTS: {
    emoji: '🏈',
  },
  ADVENTURE: {
    emoji: '🚀',
  },
  CREATIVE: {
    emoji: '🎨',
  },
  PERSONAL: {
    emoji: '🔥',
  },
}

export const orgCategories = {
  PARTY: {
    emoji: '🎉',
  },
  FESTIVAL: {
    emoji: '🎪',
  },
  DEMONSTRATION: {
    emoji: '📣',
  },
  PUB_QUIZ: {
    emoji: '🕵️',
  },
  CULTURAL: {
    emoji: '💡',
  },
  FOOD: {
    emoji: '🍕',
  },
  MARKET: {
    emoji: '🛍',
  },
  ORGANISATION: {
    emoji: '🔥',
  },
}

export const eventCategories = {
  // ORGANISATION
  PARTY: {
    emoji: '🎉',
  },
  FESTIVAL: {
    emoji: '🎪',
  },
  DEMONSTRATION: {
    emoji: '📣',
  },
  PUB_QUIZ: {
    emoji: '🕵️',
  },
  CULTURAL: {
    emoji: '💡',
  },
  FOOD: {
    emoji: '🍕',
  },
  MARKET: {
    emoji: '🛍',
  },
  ORGANISATION: {
    emoji: '🔥',
  },

  // PERSONAL
  HOUSE_PARTY: {
    emoji: '🏠',
  },
  DANCE: {
    emoji: '💃',
  },
  CHAT: {
    emoji: '💬',
  },
  DRINKS: {
    emoji: '🍻',
  },
  GAME: {
    emoji: '🎲',
  },
  SPORTS: {
    emoji: '🏈',
  },
  ADVENTURE: {
    emoji: '🚀',
  },
  CREATIVE: {
    emoji: '🎨',
  },
  PERSONAL: {
    emoji: '🔥',
  },
}

// export const getDateLines = (event) => {
//   const start = dayjs(event.startDate)
//   const end = !event.endDate ? undefined : dayjs(event.endDate)
//   if (end === undefined) {
//     return [start.format('dddd D MMMM HH:mm'), undefined]
//   } else if (end.diff(start, 'hours') < 24) {
//     return [start.format('dddd D MMMM HH:mm') + ' - ' + end.format('HH:mm'), undefined]
//   } else {
//     return [start.format('dddd D MMMM HH:mm'), end.format('dddd D MMMM HH:mm')]
//   }
// }

export const getDateLines = (event) => {
  if (!event.startDate) return [i18n.t('common:startDate'), undefined]
  const start = dayjs(event.startDate)
  const end = !event.endDate ? null : dayjs(event.endDate)
  let startText = start.format('dddd D MMMM HH:mm')
  if (event.type === 'OPEN' && isNow(event)) {
    startText = i18n.t('common:now')
  } else if (event.type === 'PINNING' || event.dateToBeAnnounced) {
    startText = i18n.t('common:eventSections.tba')
  }

  if (!end) {
    return [startText, undefined]
  } else if (end.diff(start, 'hours') < 24) {
    return [startText + ' - ' + end.format('HH:mm')]
  } else {
    return [startText, end.format('dddd D MMMM HH:mm')]
  }
}

const getDateFormat = (displayDate, small) =>
  dayjs().isSame(displayDate, 'year') ? (small ? 'D MMM' : 'dddd, MMM D') : (small ? 'D MMM YYYY' : 'dddd, MMM D YYYY')

export const getStartDateText = (event, small = false) => {
  if (event.dateToBeAnnounced || event.type === 'PINNING')
    return i18n.t('common:eventSections.tba')
  if (!event.startDate) return i18n.t('common:soon')
  const start = dayjs(event.startDate)
  const result = start.format(getDateFormat(start, small))
  const capitalized = result.charAt(0).toUpperCase() + result.slice(1)
  return capitalized
}

export const getEndDateText = (event, small = false) => {
  if (!event.endDate) return null
  const start = dayjs(event.startDate)
  const end = dayjs(event.endDate)
  console.log(start.diff(end, 'hour'))
  if (end.diff(start, 'hour') < 12) return null
  const result = end.format(getDateFormat(end, small))

  const capitalized = result.charAt(0).toUpperCase() + result.slice(1)
  return capitalized
}

export const getStartTime = (event) => {
  if (!event.startDate || event.type === 'PINNING' || event.dateToBeAnnounced)
    return null
  const start = dayjs(event.startDate)
  return start.format('LT')
}

export const getEndTime = (event) => {
  if (!event.endDate || event.type === 'PINNING' || event.dateToBeAnnounced)
    return null
  const start = dayjs(event.endDate)
  return start.format('LT')
}

export const isNow = (event) => {
  return (
    dayjs().isBefore(dayjs(event.startDate).add(8, 'hour')) &&
    dayjs().isAfter(dayjs(event.startDate))
  )
}

export const getDaysFromNow = (date) => {
  const currentDate = dayjs()
  let fromDate = dayjs().startOf('day').year(currentDate.year())
  let toDate = dayjs(date).startOf('day').year(currentDate.year())

  if (toDate.isBefore(fromDate)) {
    fromDate = fromDate.year(currentDate.year())
    toDate = toDate.year(currentDate.year() + 1)
  } else {
    fromDate = fromDate.year(currentDate.year())
    toDate = toDate.year(currentDate.year())
  }

  return toDate.diff(fromDate, 'day')
}

export const getCardDate = (event) => {
  const date = dayjs(event.startDate)

  // const emoji = (event.category && event.privacyType !== 'PRIVATE') ? eventCategories[event.category]?.emoji + ' ' : ''

  if (!event.startDate) return i18n.t('common:startDate')

  if (event.type !== 'PINNING') {
    return date.format('dddd D MMMM')
  } else if (event.dateOptions !== null) {
    return (
      date.format('dddd D MMMM') +
      ' + ' +
      (event.dateOptions.length - 1) +
      (event.dateOptions.length > 2
        ? ' ' + i18n.t('common:options').toLowerCase()
        : ' ' + i18n.t('common:option').toLowerCase())
    )
  }
}

export const getEventTitle = (event) => {
  if (event.state === 'CANCELED') {
    return event.name + ' - ' + i18n.t('common:canceled')
  } else {
    return event.name
  }
}

export const getNumberOfAttendees = (event) => {
  return event.type === 'PINNING'
    ? event.statusCounts.gaveAvailability + event.statusCounts.invited
    : event.statusCounts.going +
        event.statusCounts.maybe +
        event.statusCounts.invited
}

export const getTitle = (event) => {
  const canceledText =
    event.state === 'CANCELED' ? ' - ' + i18n.t('common:canceled') : ''
  const emoji = event.type === 'OPEN' && event.emoji ? event.emoji + ' ' : ''
  if (!event.name) return i18n.t('common:title')

  return emoji + event.name + canceledText
}

export const getGuestName = (guest) => {
  if (!guest) return ''
  if (guest.user != null && guest.user.name != null) return guest.user.name
  if (guest.name != null) return guest.name
  return null
}

export const getGuestFirstName = (guest) =>
  guest.user?.firstName || guest.user?.name || guest.name || ''

export const getUserFirstName = (user) => user?.firstName || user?.name

const sortByName = (a, b) => {
  const nameA = getGuestName(a).toUpperCase()
  const nameB = getGuestName(b).toUpperCase()
  if (nameA < nameB) return -1
  else if (nameA > nameB) return 1
  else return 0
}

const sortByStatus = (a, b) => {
  const statusOrder = {
    GOING: 0,
    MAYBE: 1,
    CANT: 2,
    NONE: 3,
  }
  if (statusOrder[a.status] < statusOrder[b.status]) return -1
  else if (statusOrder[a.status] > statusOrder[b.status]) return 1
  else return 0
}

export const sortByIsUser = (a, b) => {
  if (a.user && !b.user) return -1
  if (!a.user && b.user) return 1
  return 0
}

const sortByIsAdded = (a, b) => {
  if (a.justAdded && !b.justAdded) return -1
  if (!a.justAdded && b.justAdded) return 1
  return 0
}

const sortByCurrentGuest = (currentGuestId, a, b) => {
  if (a.id === currentGuestId) return -1
  if (b.id === currentGuestId) return 1
  return 0
}

export const draftToEvent = (draft, preferGPTImage) => {
  // Some extra processing on GPT data
  var ticketOptions = JSON.parse(draft.ticketOptions ?? '[]')
  ticketOptions.forEach((_, index, array) => {
    var ticketOption = array[index]
    if (!ticketOption.name || ticketOption.name === '') {
      ticketOption.name = 'Tickets'
    }
    if (ticketOption.availabilityType && ticketOption.availabilityType !== '') {
      if (ticketOption.availabilityType.toLowerCase() === 'available') {
        ticketOption.availabilityType = 'AVAILABLE'
      } else if (
        ticketOption.availabilityType.toLowerCase() === 'unavailable'
      ) {
        ticketOption.availabilityType = 'UNAVAILABLE'
      } else if (ticketOption.availabilityType.toLowerCase() === 'date_range') {
        ticketOption.availabilityType = 'DATE_RANGE'
      } else {
        ticketOption.availabilityType = 'AVAILABLE'
      }
    } else {
      ticketOption.availabilityType = 'AVAILABLE'
    }
    array[index] = ticketOption
  })
  return {
    ...initialEventDraft,
    draft: draft,
    organisation: draft.organisation && {
      ...draft.organisation,
      avatarUrls: {
        lg: draft.organisation?.avatar?.urls?.lg,
      },
    },
    hosts: draft.organisation
      ? [
          {
            type: 'ORGANISATION',
            model: {
              ...draft.organisation,
              avatarUrls: {
                lg: draft.organisation?.avatar?.urls?.lg,
              },
            },
          },
        ]
      : [
          {
            type: 'GUEST',
            model: {
              user: { name: '' },
            },
          },
        ],
    privacyType: draft.organisation?.privateProfile ? 'FOLLOWERS' : 'PUBLIC',
    madeChange: false,
    state: 'NEW',
    name: draft.name,
    description: draft.description,
    location: draft.location,
    coordinates:
      (draft.latitude != null &&
        draft.longitude != null && {
          type: 'Point',
          coordinates: [draft.longitude, draft.latitude],
        }) ||
      undefined,
    address: draft.address,
    startDate: draft.startDate,
    endDate: draft.endDate,
    timeZone: draft.organisation?.timeZone || 'Europe/Amsterdam',
    ticketLink: draft.ticketUrl,
    ticketDisplayLink: draft.ticketUrl,
    type: 'INVITE',
    chatGroup: {
      state: 'ADMIN_ONLY',
    },
    categoryId: draft.eventCategoryId,
    categoryObj: draft.eventCategory
      ? {
          id: draft.eventCategory.id,
          name: 'placeholder',
          active: true,
          weight: draft.eventCategory.weight,
          showInFilter: true,
          emoji: draft.eventCategory.emoji,
          featured: false,
        }
      : null,
    imageUrls: {
      lg:
        draft?.imageUrl ??
        (preferGPTImage
          ? draft?.signedStorageImageUrlGPT
          : draft?.signedStorageImageUrl),
    },
    ticketOptions: ticketOptions,
    minimumAgeToJoin: draft.minimumAgeToJoin,
  }
}
