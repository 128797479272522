import apiClient from 'shared-components/utils/ApiClient'
import mixpanel from 'shared-components/utils/mixpanel'

import confetti from 'canvas-confetti'
import i18n from 'i18next'
import {
  getActiveGuest,
  getCurrentEvent,
  getUserHasTicket,
  hasTicketing,
} from 'selectors/event'
import { setSnackbar } from '../shared-components/redux/notifications/actions'
import { mixpanelTrackEventState } from '../utils/useMixpanelTrackEvent'
import {
  HYPE_EVENT,
  RSVP_EVENT,
  setCurrentGuest,
  setDateOptions,
  WAITLIST_EVENT,
} from './event'
import { closeRsvpModal, closeTicketsModal } from './modals'
import { rsvpDateOptions } from './pinning'

export const SET_EMAIL = 'SET_EMAIL'
export const SET_NOTIFICATION_TOKEN = 'SET_NOTIFICATION_TOKEN'
export const SET_RESPONSE_REASON = 'SET_RESPONSE_REASON'
export const SET_NEW_GUEST_NAME = 'SET_NEW_GUEST_NAME'
export const SET_NEW_GUEST_FIRST_NAME = 'SET_NEW_GUEST_FIRST_NAME'
export const SET_NEW_GUEST_LAST_NAME = 'SET_NEW_GUEST_LAST_NAME'
export const SET_NEW_GUEST_MESSAGE = 'SET_NEW_GUEST_MESSAGE'
export const SET_SEARCH = 'SET_SEARCH'
export const SET_NEW_GUEST_STATUS = 'SET_NEW_GUEST_STATUS'
export const SET_NEW_GUEST_WAITINGLIST = 'SET_NEW_GUEST_WAITINGLIST'
export const SET_RSVP_LOCATION = 'SET_RSVP_LOCATION'
export const SET_CHANGING_STATUS = 'SET_CHANGING_STATUS'
export const SET_SELECTED_GUEST = 'SET_SELECTED_GUEST'
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP'
export const RESET_PAYMENT_DETAILS = 'RESET_PAYMENT_DETAILS'
export const SET_COMM_METHOD = 'SET_COMM_METHOD'
export const SET_SELECTED_TICKET_PRODUCT = 'SET_SELECTED_TICKET_PRODUCT'
export const FETCH_PAYMENT_DETAILS = 'FETCH_PAYMENT_DETAILS'
export const START_CHECK_ORDER_STATUS = 'START_CHECK_ORDER_STATUS'
export const OPEN_RSVP_ON_STEP = 'OPEN_RSVP_ON_STEP'
export const SET_CURRENT_QUESTION_ID = 'SET_CURRENT_QUESTION_ID'
export const SET_FORM_RESPONSES = 'SET_FORM_RESPONSES'
export const RESET_FORM_RESPONSES = 'RESET_FORM_RESPONSES'
export const SET_JOIN_OPTION = 'SET_JOIN_OPTION'
export const SET_SCROLL_TO_CHAT = 'SET_SCROLL_TO_CHAT'

export const getLocalDirect = (eventCode) => {
  if (typeof window === 'undefined') return undefined
  return (
    (window.localStorage &&
      window.localStorage.getItem(`direct_${eventCode}`)) ||
    undefined
  )
}

export const setEmail = (email) => ({
  type: SET_EMAIL,
  payload: email,
})

export const setScrollToChat = (value) => ({
  type: SET_SCROLL_TO_CHAT,
  payload: value,
})

export const setNotificationToken = (notificationToken) => ({
  type: SET_NOTIFICATION_TOKEN,
  payload: notificationToken,
})

export const setResponseReason = (responseReason) => ({
  type: SET_RESPONSE_REASON,
  payload: responseReason,
})

export const setChangingStatus = (changingStatus) => ({
  type: SET_CHANGING_STATUS,
  payload: changingStatus,
})

export const setSearch = (search) => ({
  type: SET_SEARCH,
  payload: search,
})

export const setCurrentQuestionId = (id) => ({
  type: SET_CURRENT_QUESTION_ID,
  payload: id,
})

export const setCurrentFormResponses = (responses) => ({
  type: SET_FORM_RESPONSES,
  payload: responses,
})

export const resetCurrentFormResponses = () => ({
  type: RESET_FORM_RESPONSES,
})

export const setCurrentTicketOption = (responses) => ({
  type: SET_JOIN_OPTION,
  payload: responses,
})

export const setNewGuestStatus = (status) => (dispatch, getState) => {
  // mixpanelTrackEventState('RSVP', getCurrentEvent(getState()), { 'RSVP Status': status }, getState())
  // mixpanel.trackEvent('RSVP', getCurrentEvent(getState()), { 'RSVP Status': status })
  dispatch({
    type: SET_NEW_GUEST_STATUS,
    payload: status,
  })
}

export const setNewGuestWaitingList =
  (status) => async (dispatch, getState) => {
    // mixpanelTrackEventState('RSVP', getCurrentEvent(getState()), { 'RSVP Status': status }, getState())
    // mixpanel.trackEvent('RSVP', getCurrentEvent(getState()), { 'RSVP Status': status })
    await dispatch({
      type: SET_NEW_GUEST_WAITINGLIST,
      payload: status,
    })
  }

export const setRsvpLocation = (status) => async (dispatch, getState) => {
  // mixpanelTrackEventState('RSVP', getCurrentEvent(getState()), { 'RSVP Status': status }, getState())
  // mixpanel.trackEvent('RSVP', getCurrentEvent(getState()), { 'RSVP Status': status })
  await dispatch({
    type: SET_RSVP_LOCATION,
    payload: status,
  })
}

export const onChangeAddGuestName = (guestName) => ({
  type: SET_NEW_GUEST_NAME,
  payload: guestName,
})

export const onChangeAddGuestFirstName = (guestName) => ({
  type: SET_NEW_GUEST_FIRST_NAME,
  payload: guestName,
})

export const onChangeAddGuestLastName = (guestName) => ({
  type: SET_NEW_GUEST_LAST_NAME,
  payload: guestName,
})

export const onChangeAddGuestMessage = (message) => ({
  type: SET_NEW_GUEST_MESSAGE,
  payload: message,
})

export const addGuestByName = () => (dispatch, getState) => {
  const state = getState()
  const name =
    state.rsvp.newGuest.firstName + ' ' + state.rsvp.newGuest.lastName
  mixpanelTrackEventState(
    'Confirm Name',
    getCurrentEvent(state),
    { 'Confirm Method': 'Manually Added', 'Guest Name': name },
    state,
  )
  // mixpanel.trackEvent('Confirm Name', getCurrentEvent(getState()), { 'Confirm Method': 'Manually Added', 'Guest Name': name })
  mixpanel.people.set({ $name: name })
}

export const rsvpAsUser =
  (status, location, stripeCustomerId) => async (dispatch, getState) => {
    const state = getState()
    const event = getCurrentEvent(state)
    const newGuest = state.rsvp.newGuest
    mixpanelTrackEventState(
      'RSVP',
      event,
      { 'RSVP Status': status },
      getState(),
    )

    const userHasTicket = getUserHasTicket(state)
    if (hasTicketing(state) && status === 'GOING' && !userHasTicket) {
      if (event.ticketLink) {
        // dispatch(openRsvpOnStep('ticket-link'))
        // dispatch(openRsvpOnStep('done'))
      } else {
        mixpanel.track('Land on Ticket Dialog')
        dispatch(openRsvpOnStep('entry-type'))
        return
      }
    }

    if (event.type === 'PINNING') {
      const dateOptions = Object.keys(state.pinning.dateResponses).map(
        (id) => ({
          id,
          status: state.pinning.dateResponses[id],
        }),
      )
      await dispatch(
        rsvpDateOptions(
          event.id,
          { dateOptions },
          event.code,
          undefined,
          location,
          event.invitedBy?.id,
        ),
      )
      dispatch(patchDateOptionsLocal(dateOptions))
    } else {
      await dispatch({
        type: RSVP_EVENT,
        payload: apiClient.rsvp.normal(
          event.id,
          { ...newGuest, stripeCustomerId },
          event.code,
          location,
          null,
          event.invitedBy?.id,
        ),
      })
      dispatch(setCurrentTicketOption(null))
    }
    dispatch(setChangingStatus(false))
    dispatch(resetCurrentFormResponses())
  }

export const rsvpWaitList =
  (location, guestCode) => async (dispatch, getState) => {
    const state = getState()
    const event = getCurrentEvent(state)
    var newGuest = state.rsvp.newGuest
    await dispatch({
      type: WAITLIST_EVENT,
      payload: apiClient.rsvp.waitlist(
        event.id,
        {
          guestInfo: newGuest,
          waitingList: true,
        },
        event.code,
        location,
        guestCode,
        event.invitedBy?.id,
      ),
    })
    dispatch(resetCurrentFormResponses())
    dispatch(setCurrentTicketOption(null))
    dispatch(setChangingStatus(false))
    dispatch(setNewGuestWaitingList(false))
  }

export const rsvpHype =
  (location, guestCode, hype) => async (dispatch, getState) => {
    const state = getState()
    const event = getCurrentEvent(state)
    var newGuest = state.rsvp.newGuest
    await dispatch({
      type: HYPE_EVENT,
      payload: apiClient.rsvp.hype(
        event.id,
        {
          guestInfo: newGuest,
          hype: hype,
        },
        event.code,
        location,
        guestCode,
        event.invitedBy?.id,
      ),
    })
    dispatch(resetCurrentFormResponses())
    dispatch(setCurrentTicketOption(null))
    dispatch(setChangingStatus(false))
    dispatch(setNewGuestWaitingList(false))
  }

export const rsvpAsGuest =
  (guestCode, location, stripeCustomerId) => async (dispatch, getState) => {
    const state = getState()
    const event = getCurrentEvent(state)
    var newGuest = state.rsvp.newGuest
    newGuest.languageCode = i18n.language || 'en'
    newGuest.distinctId = mixpanel.get_distinct_id()
    if (!newGuest.name && !guestCode) throw Error('Empty name field')

    if (event.type === 'PINNING') {
      const dateOptions = Object.keys(state.pinning.dateResponses).map(
        (id) => ({
          id,
          status: state.pinning.dateResponses[id],
        }),
      )
      await dispatch(
        rsvpDateOptions(
          event.id,
          { dateOptions, guestInfo: newGuest },
          event.code,
          guestCode,
          location,
          event.invitedBy?.id,
        ),
      )
      await dispatch(patchDateOptionsLocal(dateOptions))
    } else {
      return await dispatch({
        type: RSVP_EVENT,
        payload: apiClient.rsvp.normal(
          event.id,
          { ...newGuest, guestInfo: { ...newGuest, stripeCustomerId } },
          event.code,
          location,
          guestCode,
          event.invitedBy?.id,
        ),
      })
    }
  }

export const patchDateOptionsLocal =
  (userDateOptions) => (dispatch, getState) => {
    const state = getState()
    const event = getCurrentEvent(state)
    const activeGuest = getActiveGuest(state)
    const currentDateOptions = event.dateOptions
    const newDateOptions = currentDateOptions.map((currentDateOption) => {
      const userDateOption = userDateOptions.find(
        (d) => parseInt(d.id) === currentDateOption.id,
      )
      if (userDateOption == null) return currentDateOption
      let newGuestStatuses = []
      if (currentDateOption.guestStatuses) {
        const guestStatusIdx = currentDateOption.guestStatuses.findIndex(
          (s) => s.guest.id === activeGuest.id,
        )
        if (guestStatusIdx === -1) {
          newGuestStatuses = [
            ...currentDateOption.guestStatuses,
            { guest: activeGuest, status: userDateOption.status },
          ]
        } else {
          newGuestStatuses = currentDateOption.guestStatuses.map((gs, i) =>
            i === guestStatusIdx
              ? { ...gs, status: userDateOption.status }
              : gs,
          )
        }
      } else {
        newGuestStatuses = [
          { guest: activeGuest, status: userDateOption.status },
        ]
      }
      return { ...currentDateOption, guestStatuses: newGuestStatuses }
    })
    dispatch(setDateOptions(newDateOptions))
  }

export const setCurrentStep = (step) => ({
  type: SET_CURRENT_STEP,
  payload: step,
})

export const resetPaymentDetails = () => ({
  type: RESET_PAYMENT_DETAILS,
})

export const openRsvpOnStep = (step) => ({
  type: OPEN_RSVP_ON_STEP,
  payload: step,
})

export const setCommMethod = (method) => ({
  type: SET_COMM_METHOD,
  payload: method,
})

export const setSelectedTicketProduct = (selectedTicketProduct) => ({
  type: SET_SELECTED_TICKET_PRODUCT,
  payload: selectedTicketProduct,
})

export const startCheckOrderStatus = (orderId) => ({
  type: START_CHECK_ORDER_STATUS,
  payload: orderId,
})

export const checkOrderStatus = (eventId, orderId, eventCode) => ({
  type: FETCH_PAYMENT_DETAILS,
  payload: apiClient.ticket.checkStatus(eventId, orderId, eventCode),
})

export const checkCurrentOrderStatus = () => (dispatch, getState) => {
  const state = getState()
  const event = getCurrentEvent(state)
  const orderId = state.rsvp.paymentDetails
    ? state.rsvp.paymentDetails.id
    : state.rsvp.orderId
  dispatch(checkOrderStatus(event.id, orderId, event.code))
}

export const cancelOrder = (eventId, orderId, eventCode) => ({
  type: FETCH_PAYMENT_DETAILS,
  payload: apiClient.ticket.cancel(eventId, orderId, eventCode),
})

export const cancelCurrentOrder = () => (dispatch, getState) => {
  const state = getState()
  const event = getCurrentEvent(state)
  const orderId = state.rsvp.paymentDetails
    ? state.rsvp.paymentDetails.id
    : state.rsvp.orderId
  dispatch(cancelOrder(event.id, orderId, event.code))
}

export const closeAndResetRsvpModal = () => async (dispatch, getState) => {
  const state = getState()

  await dispatch(closeRsvpModal())
  setTimeout(() => {
    dispatch(setCurrentStep('start'))
    dispatch(onChangeAddGuestMessage(''))
  }, 200)
  dispatch(closeTicketsModal())
  dispatch(resetPaymentDetails())

  // remove orderId queryparam from url
  if (typeof window !== 'undefined') {
    const newURL = window.location.href.split('?')[0]
    window.history.pushState({ path: newURL }, document.title, newURL)
  }
}

export const showConfetti = () => (dispatch) => {
  dispatch(setSnackbar('notified', i18n.t('common:thanksForResponse')))

  function fire(particleRatio, opts) {
    confetti(
      Object.assign({}, defaults, opts, {
        particleCount: Math.floor(count * particleRatio),
      }),
    )
  }

  // Confetti
  var count = 200
  var defaults = {
    origin: { y: 0.7 },
    zIndex: 1000000,
    shapes: ['square'],
    colors: ['A373F1', '#FDF0ED', 'DC8DDE', '#FFEB84', '#FFFFFF'],
  }

  fire(0.25, {
    spread: 26,
    startVelocity: 55,
  })
  fire(0.2, {
    spread: 60,
  })
  fire(0.35, {
    spread: 100,
    decay: 0.91,
    scalar: 0.8,
  })
  fire(0.1, {
    spread: 120,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2,
  })
  fire(0.1, {
    spread: 120,
    startVelocity: 45,
  })
}
